import * as actionTypes from "./actionTypes";
import { AppAction, AppState } from './types';


const initialState: AppState = {
	toast: {},
	mapViewPort: {
		latitude: 22.286571,
		longitude: 114.150484,
		zoom: 9.5,
		bearing: 0,
		pitch: 0
	},
	controlPanelTree: [],
	posts: [],
	selectedPost: null,
	showPopUp: false,
	showProgressSpinner: false
}

const reducer = (state = initialState, action: AppAction) => {
	switch (action.type) {
		case actionTypes.SHOW_POPUP:
			return {
				...state,
				showPopUp: true
			}
		case actionTypes.HIDE_POPUP:
			return {
				...state,
				showPopUp: false
			}
		case actionTypes.SHOW_PROGRESS_SPINNER:
			return {
				...state,
				showProgressSpinner: true
			}
		case actionTypes.HIDE_PROGRESS_SPINNER:
			return {
				...state,
				showProgressSpinner: false
			}
		case actionTypes.SET_TOAST:
			return {
				...state,
				toast: action.toast
			}
		case actionTypes.SET_VIEWPORT:
			return {
				...state,
				mapViewPort: action.viewPortConfig
			}
		case actionTypes.GET_CPANEL_TREE:
			return {
				...state,
				controlPanelTree: action.tree
			}
		case actionTypes.GET_POSTS:
			return {
				...state,
				posts: action.posts
			}
		case actionTypes.SET_SELECTED_POST:
			return {
				...state,
				selectedPost: action.post
			}
		default:
			return state
	}
}

export default reducer
