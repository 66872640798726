import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import './submit-dialog.css'
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { ApiService } from '../services/ApiService';
import { Dropdown } from 'primereact/dropdown';
import { IPost } from '../interfaces/IPost';
import { isBrowser } from 'react-device-detect';
import { Editor } from '@tinymce/tinymce-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/types';

const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
const youTubeRegex =/(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/g

const tinyAPIKey = 'oc7harozu0n9hnn42y0vtl3gc6j8fceempc0ugog2nlgcdm6'


export const SubmitDialog = (props: any) => {
	const {visible, onHide, onSubmit, location} = props

	const isProgressSpinnerVisible: any = useSelector(
		(state: AppState) => state.showProgressSpinner,
		shallowEqual
	);

	const op = useRef(null);
	const kmlIconHelp = useRef(null);

	const [postCategory, setPostCategory] = useState('');
	const [categories, setCategories] = useState([]);
	const [postTitle, setPostTitle] = useState('');
	const [postLocation, setPostLocation] = useState('');
	const [postDescription, setPostDescription] = useState('');
	const [postExternalUrl, setPostExternalUrl] = useState('');
	const [postYoutubeUrl, setPostYoutubeUrl] = useState('');

	const [agree, setAgree] = useState(null);

	const [errors, setErrors]: any = useState({})

	let formData: FormData = new FormData();

	let photoUploadRef: any
	let kmlUploadRef: any

	useEffect(() => {
		const service = new ApiService()
		service.getCategories().then((result) => {
			const cat = result.data
			setCategories(cat.map( (c: any) => {
				return {
					label: c.name,
					value: c.id
				}
			}))
		})
	}, [])

	useEffect(() => {
		if (location) {
			setPostLocation(location.join(', '))
		}
	}, [location])

	useEffect(() => {
		const locationRegEx = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/g
		const match = postLocation.match(locationRegEx)
		if (match) {
			delete errors['postLocation']
			setErrors({...errors})
		} else if (postLocation.length === 0) {
			errors['postLocation'] = 'Required'
			setErrors({
				...errors,
			})
		}
		else {
			errors['postLocation'] = 'Invalid Location Format'
				setErrors({
				...errors
			})
		}
	}, [postLocation])

	useEffect(() => {
		const match = postExternalUrl.match(urlRegex)
		if (match || postExternalUrl.length === 0) {
			delete errors['postExternalUrl']
			setErrors({...errors})
		} else {
			errors['postExternalUrl'] = 'Invalid Url Format'
			setErrors({...errors})
		}
	}, [postExternalUrl])

	useEffect(() => {
		const match = postYoutubeUrl.match(youTubeRegex)
		if (match || postYoutubeUrl.length === 0) {
			delete errors['postYoutubeUrl']
			setErrors({...errors})
		} else {
			errors['postYoutubeUrl'] = 'Invalid Youtube Url Format'
			setErrors({...errors})
		}
	}, [postYoutubeUrl])

	useEffect(() => {
		if (postTitle.length === 0) {
			errors['postTitle'] = 'Required'
			setErrors({
				...errors,
			})
		}
		else if (postTitle.length > 50) {
			errors['postTitle'] = 'Must be less then 50 characters'
			setErrors({
				...errors
			})
		} else {
			delete errors['postTitle']
			setErrors({...errors})
		}
	}, [postTitle])

	useEffect(() => {
		if (postCategory === '' || postCategory === null) {
			errors['postCategory'] = 'Required'
			setErrors({
				...errors
			})
		} else {
			delete errors['postCategory']
			setErrors({...errors})
		}
	}, [postCategory])

	useEffect(() => {
		if (postDescription.length > 3500) {
			setErrors({
				...errors,
				postDescription: 'Must be less then 3500 characters'
			})
		} else {
			delete errors['postDescription']
			setErrors({...errors})
		}
	}, [postDescription])

	useEffect(() => {
		if (agree === 'no' || agree === null) {
			setErrors({
				...errors,
				postAgreement: 'You must agree to the conditions to submit a new post'
			})
		} else {
			delete errors['postAgreement']
			setErrors({...errors})
		}
	}, [agree])

	const dragNDrop = () => {
		return (
			<p className='p-m-0'>Drag and drop files to here to upload.</p>
		)
	}

	const onSubmitClick = () => {
		kmlUploadRef.upload()
		photoUploadRef.upload()
	}

	const onHideDialog = () => {
		setPostCategory('')
		setPostTitle('')
		setPostLocation('')
		setPostDescription('')
		setPostExternalUrl('')
		setPostYoutubeUrl('')
		onHide()
	}

	const onPhotoSelect = (e: any) => {

		if (photoUploadRef.files.length > 1) {
			errors['postPhoto'] = 'Cannot upload more then 1 image'
			setErrors({
				...errors,
			})
		}
	}


	const onKmlSelect = (e: any) => {
		if (kmlUploadRef.files.length > 1) {
			errors['postKml'] = 'Cannot upload more then 1 image'
			setErrors({
				...errors,
			})
		}
	}

	const onPhotoRemoved = (e: any) => {

		photoUploadRef.files = photoUploadRef.files.filter((file: any) => {
			return e.file.objectUrl = file.objectUrl
		})
		if (photoUploadRef.files.length === 1 || photoUploadRef.files.length === 0) {
			delete errors['postPhoto']
			setErrors({
				...errors,
			})
		}
	}

	const onKmlRemoved = (e: any) => {

		kmlUploadRef.files = kmlUploadRef.files.filter((file: any) => {
			return e.file.objectUrl = file.objectUrl
		})
		if (kmlUploadRef.files.length === 1 || kmlUploadRef.files.length === 0) {
			delete errors['postKml']
			setErrors({
				...errors,
			})
		}
	}


	const photoUploadHandler = (e: any) => {
		const newPost: IPost = {
			categoryId: postCategory,
			title: postTitle,
			location: {type: 'Point', coordinates: postLocation.split(',').map((p: any) => parseFloat(p))},
			textForMarker: postDescription,
			externalSiteLink: postExternalUrl,
			youtubeLink: postYoutubeUrl
		}

		if (!formData.has('form')) {
			formData.append('form', JSON.stringify(newPost))
		}

		if (e.files.length === 0) {
			formData.append('files[]', '');
		}
		if (e.files.length === 1) {
			formData.append('files[]', e.files[0], e.files[0].name);
			delete errors['postPhoto']
			setErrors({
				...errors,
			})
		}

		onSubmit(formData)
	}

	const kmlUploadHandler = (e: any) => {
		if (e.files.length === 1) {
			formData.append('files[]', e.files[0], e.files[0].name);
			delete errors['postKml']
			setErrors({
				...errors,
			})
		}
	}

	const renderFooter = () => {
		return (
			<div>
				<Button label='Submit'
						icon='pi pi-check'
						onClick={onSubmitClick}
						className='p-button-text'
						disabled={Object.keys(errors).length > 0}
				/>
				{
					isProgressSpinnerVisible && <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
				}

			</div>
		);
	}

	const dialogStyle = isBrowser ? {width: '50vw'} : {width: '95vw'}

	return (
		<Dialog
			header='Submit New Post'
			visible={visible}
			style={dialogStyle}
			onHide={onHideDialog}
			footer={renderFooter}
		>
			<Panel header='Guidelines' toggleable collapsed>
				<p>This site and content are intended to be 100% anonymous.
					No identifying information is to be given, or it will not be used.
					For photos, you can use this <a href='https://link.bewater.la/scrub' target="_blank">Image Scrubber</a> to remove sensitive metadata,
					blur faces and other identifiable information.</p>
				<p>If your submission does not fit guidelines for any other reason, it will not be posted.
					Submissions will be reviewed and added to the map on a monthly basis. If you haven't seen your submission go up,
					please check that you have followed the guidelines and resubmit. </p>
				<p><b>Video note: Please use an alias account</b> that isn't
					tied to your personal information. To ensure that you've done this, <b>please amend your alias with
						the letters HKO</b>. Then we'll be sure you followed instructions. We can accept Vimeo or YouTube links. <b>Vimeo is more anonymous by default.</b></p>
			</Panel>
			<div className='p-fluid'>
				<div className='p-field'>
					<label htmlFor='postCategory'>Submission Category</label>
					<Dropdown value={postCategory} options={categories} onChange={(e) => setPostCategory(e.value)} placeholder='Select a Category'/>
					{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postCategory']}</div> : <></>}
				</div>
				<div className='p-field'>
					<label htmlFor='title'>Title (max 50 characters)</label>
					<InputText id='title' type='text' onChange={(e: any) => setPostTitle(e.target.value)}/>
					{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postTitle']}</div> : <></>}
				</div>
				<div className='p-field'>
					<label htmlFor='location'>Longitude and Latitude</label>
					<InputText id='location' type='text'
							   readOnly
							   disabled
							   value={postLocation}
							   onChange={(e: any) => setPostLocation(e.target.value)}
							   onPaste={(e: any) => setPostLocation(e.target.value)}
					/>
					{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postLocation']}</div> : <></>}
				</div>
				<div className='p-field'>
					<label htmlFor='kmlUpload'>OR Upload Journey / Path</label>

					<i ref={kmlIconHelp} className='pi pi-info-circle kml-tooltip'
					   style={{marginLeft: '10px'}}
					   onClick={(e) => op.current.toggle(e)}
					></i>
					<Tooltip target='.kml-tooltip' position='top'>
						Click here to see how to upload a path
					</Tooltip>
					<OverlayPanel ref={op} appendTo={document.body}>
						<iframe
							src="https://player.vimeo.com/video/526770032?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
							width="480" height="270" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
							allowFullScreen title="Export a path to KML"></iframe>
					</OverlayPanel>

					<FileUpload id='kmlUpload' name='file'
								ref={ref => { kmlUploadRef = ref}}
								customUpload={true}
								uploadHandler={kmlUploadHandler}
								onSelect={onKmlSelect}
								onRemove={onKmlRemoved}
								accept='application/vnd.google-earth.kml+xml;' maxFileSize={1000000}
								emptyTemplate={dragNDrop} />
					{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postKml']}</div> : <></>}
				</div>
				<div className='p-field'>
					<label htmlFor='photoUpload'>Photo Upload</label>
					<FileUpload id='photoUpload' name='file'
								ref={ref => { photoUploadRef = ref}}
								customUpload={true}
								uploadHandler={photoUploadHandler}
								onSelect={onPhotoSelect}
								onRemove={onPhotoRemoved}
								accept='image/*' maxFileSize={3000000}
								emptyTemplate={dragNDrop} />
					{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postPhoto']}</div> : <></>}
				</div>

				<div className='p-field'>
					<label htmlFor='description'>Text for Marker</label>
					<Tooltip target='.description-tooltip' position='top'>
						Include either accompanying photo/video/link text, or standalone piece of writing. 3,500 characters max.
					</Tooltip>
					<i className='pi pi-info-circle description-tooltip' style={{marginLeft: '10px'}}></i>
					<Editor
						apiKey={tinyAPIKey}
						init={{
							height: 500,
							menubar: false,
							plugins: [
								'advlist autolink lists link image charmap print preview anchor',
								'searchreplace visualblocks code hr fullscreen',
								'insertdatetime media table paste code help wordcount'
							],
							toolbar:
								'undo redo | formatselect | bold italic backcolor | \
								alignleft aligncenter alignright alignjustify | \
								bullist numlist outdent indent hr | code removeformat'
						}}
						onEditorChange={(e: any) => setPostDescription(e)}
					/>

					{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postDescription']}</div> : <></>}
				</div>
				<div className='p-field'>
					<label htmlFor='externalUrl'>External Site Link</label>
					<Tooltip target='.externalUrl-tooltip' position='top'>
						If directing to another site, link here. No shortened URLs, no redirects.
					</Tooltip>
					<i className='pi pi-info-circle externalUrl-tooltip' style={{marginLeft: '10px'}}></i>
					<InputText id='externalUrl' type='text' onChange={(e: any) => setPostExternalUrl(e.target.value)}/>
					{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postExternalUrl']}</div> : <></>}
				</div>
				<div className='p-field'>
					<label htmlFor='youtubeUrl'>Video Link</label>
					<Tooltip target='.youtubeUrl-tooltip' position='top'>
						Please copy/paste the link to your Vimeo or YouTube video here. See guidelines for further details.
					</Tooltip>
					<i className='pi pi-info-circle youtubeUrl-tooltip' style={{marginLeft: '10px'}}></i>
					<InputText id='youtubeUrl' type='text' onChange={(e: any) => setPostYoutubeUrl(e.target.value)}/>
					{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postYoutubeUrl']}</div> : <></>}
				</div>
				<div>Are you the rights holder of the content you are submitting, and do you consent to it being published on bewater.la?</div>
				<div className='p-field-radiobutton'>
					<RadioButton inputId='yes' name='agree' value='yes' onChange={(e) => setAgree(e.value)} checked={agree === 'yes'} />
					<label style={{lineHeight: 0, marginLeft: '5px'}}>Yes</label>
				</div>
				<div className='p-field-radiobutton'>
					<RadioButton inputId='no' name='agree' value='no' onChange={(e) => setAgree(e.value)} checked={agree === 'no'} />
					<label style={{lineHeight: 0, marginLeft: '5px'}}>No</label>
				</div>
				{ Object.keys(errors).length > 0 ? <div style={{color: 'red'}}>{errors['postAgreement']}</div> : <></>}
			</div>
		</Dialog>
	)
}
