import * as React from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Tree } from 'primereact/tree';
import './control-panel.css'
import '../../node_modules/antd/dist/antd.css';
import { AboutDialog } from './AboutDialog';
import { SubmitDialog } from './SubmitDialog';
import { ApiService } from '../services/ApiService';
import line_graph_icon from './../icons/line_graph.png'
import { IPost } from '../interfaces/IPost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile
} from 'react-device-detect';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { hideProgressSpinner, showProgressSpinner } from '../store/actionCreators';

interface ICity {
	city: string
	population: string
	image: string
	state: string
	latitude: number
	longitude: number
}


export const ControlPanel = (props: any) => {
	const dispatch: Dispatch<any> = useDispatch()
	const [expandedKeys, setExpandedKeys] = useState({});
	const [displayDialogAbout, setDisplayDialogAbout] = useState(false)
	const [displayDialogSubmit, setDisplayDialogSubmit] = useState(false)
	const toast = useRef(null);
	const panel: any = useRef(null)


	const onClick = props.onClick
	const treeData = props.tree
	const clickedLocation = props.clickedLocation

	useEffect(() => {
		const a = ';'
		if (clickedLocation) {
			setDisplayDialogSubmit(true)
		}
	}, [clickedLocation])

	const onSubmit = (formData: FormData) => {
		const service = new ApiService()
		dispatch(showProgressSpinner())
		service.submitPost(formData).then((r: any) => {
			dispatch(hideProgressSpinner())
			toast.current.show({severity:'success', summary: 'Post Submitted', detail:'It will be added after a review', life: 3000});
			setDisplayDialogSubmit(false)
		}).catch((e) => {
		})
	}

	const onSelect = (e: {originalEvent: Event, node: any}) => {

		let _expandedKeys: any = {...expandedKeys};
		if (_expandedKeys[e.node.key])
			delete _expandedKeys[e.node.key];
		else
			_expandedKeys[e.node.key] = true;

		setExpandedKeys(_expandedKeys);
		if (!e.node.children) {
			onClick(e.node.post)
			if (isMobile) {
				panel.current.toggle(e.originalEvent)
			}

		}
	}

	const onHideAboutDialog = () => {
		setDisplayDialogAbout(false);
	}

	const onHideSubmitDialog = () => {
		setDisplayDialogSubmit(false);
	}


	const nodeTemplate = (node: any) => {
		const nodeColor = node.markerColor
		if (node.children) {
			return (
				<div>{node.label}</div>
			)
		} else {
			const post: IPost = node.post
			if (post.location.type === 'LineString') {
				return (
					<div className='container'>
						<img src={line_graph_icon} style={{height: '20px', float: 'right', marginTop: '5px'}}/>
						<div className='item-color' style={{'backgroundColor': nodeColor}}></div>
						<div className='node-title'>{node.label}</div>
					</div>
				)
			}
			if (post.location.type === 'Point') {
				return (
					<div className='container'>
						<div style={{height: '20px', float: 'right'}}>
							<FontAwesomeIcon icon={faMapMarkerAlt} size='1x' color={node.markerColor} />
						</div>

						<div className='item-color' style={{'backgroundColor': nodeColor}}></div>
						<div className='node-title'>{node.label}</div>
					</div>
				)
			}

		}

	}

	const onAboutClick = () => {
		setDisplayDialogAbout(true)
	}

	const cPanel = useMemo(() => {
		return (
			<div className={isBrowser ? 'control-panel' : 'control-panel-mobile'}>
				<Toast ref={toast} />

				<AboutDialog visible={displayDialogAbout} onHide={onHideAboutDialog}></AboutDialog>
				<SubmitDialog visible={displayDialogSubmit}
							  onHide={onHideSubmitDialog}
							  onSubmit={onSubmit}
							  location={clickedLocation}
				></SubmitDialog>
				<Panel ref={panel} header='be water: collected memories of our hong kong' toggleable>
					<div className='title'>Click anywhere on the map to create a new post</div>
					<div className='about' onClick={onAboutClick} style={{float: 'right'}}>About</div>
					<Tree
						filter filterMode='lenient'
						selectionMode='single'
						expandedKeys={expandedKeys}
						onToggle={e => setExpandedKeys(e.value)}
						onSelect={onSelect}
						value={treeData}
						nodeTemplate={nodeTemplate}
					/>
				</Panel>
			</div>


		)
	}, [treeData, displayDialogSubmit, displayDialogAbout, expandedKeys])


	return (
		<>{cPanel}</>
	)

	// return (
	// 		<div className={isBrowser ? 'control-panel' : 'control-panel-mobile'}>
	// 			<Toast ref={toast} />
	// 			<AboutDialog visible={displayDialogAbout} onHide={onHideAboutDialog}></AboutDialog>
	// 			<SubmitDialog visible={displayDialogSubmit}
	// 						  onHide={onHideSubmitDialog}
	// 						  onSubmit={onSubmit}
	// 						  location={clickedLocation}
	// 			></SubmitDialog>
	// 			<Panel ref={panel} header='be water: collected memories of our hong kong' toggleable>
	// 				<div className='title'>Click anywhere on the map to create a new post</div>
	// 				{/*<div className='menu' onClick={() => setDisplayDialogSubmit(true)}>Submissions</div>*/}
	// 				<div className='menu' onClick={() => setDisplayDialogAbout(true)}>About</div>
	// 				<Tree
	// 					filter filterMode='lenient'
	// 					selectionMode='single'
	// 					expandedKeys={expandedKeys}
	// 					onToggle={e => setExpandedKeys(e.value)}
	// 					onSelect={onSelect}
	// 					value={treeData}
	// 					nodeTemplate={nodeTemplate}
	// 				/>
	// 			</Panel>
	// 		</div>
	//
	//
	// 	)
}
