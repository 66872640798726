import * as React from 'react';
import { IPost } from '../interfaces/IPost';
import './post-info.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import { API_ENDPOINT } from '../services/ApiEndpoint';
import Image from 'react-graceful-image'
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile
} from 'react-device-detect';

export const PostInfo = (props: any) => {

	const post: IPost = props.info;
	const displayName = post.title;
	const description = post.textForMarker

	const imgSource = post.photo ? `${API_ENDPOINT}${post.photo}` : null

	return (

		 <div className="post-info-container">
			 <div>
				 <span style={{display: 'inline-block'}}><h3>{displayName}</h3></span>
				 {post.youtubeLink && post.youtubeLink.includes('watch') && <span
                     style={{display: 'inline-block', marginLeft: '5px', cursor: 'pointer'}}
                     onClick={()=> window.open(post.youtubeLink, "_blank")}>
				 	<FontAwesomeIcon icon={faExternalLinkAlt} size="1x" color="blue" />
			 	</span>}
				 {post.externalSiteLink && <span
                     style={{display: 'inline-block', marginLeft: '5px', cursor: 'pointer'}}
                     onClick={()=> window.open(post.externalSiteLink, "_blank")}>
				 	<FontAwesomeIcon icon={faExternalLinkAlt} size="1x" color="blue" />
			 	</span>}
			 </div>

			 {post.youtubeLink && <iframe
				 className={isBrowser ? 'youtube-frame' : 'youtube-frame-mobile'}
				 width="100%"
				 height="90%"
				 src={post.youtubeLink}
				 frameBorder="0"
				 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				 allowFullScreen
				 title="Embedded youtube"/>}
			 {/*{imgSource ? <img className="post-img" src={imgSource} onClick={() => window.open(imgSource, "_blank")}></img> : <></>}*/}
			 {imgSource ? <div onClick={() => window.open(imgSource, "_blank")}><Image
				 src={imgSource}
				 width='100%'
				 height='100%'
				 className="post-img"
			 /></div> : <></>}

			{	description && description !== 'undefined' ? <div className="post-info-description">{ReactHtmlParser(description)}</div> : <></>}

		</div>


	);
}
