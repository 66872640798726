import * as actionTypes from "./actionTypes";
import { ApiService } from '../services/ApiService';
import { AppAction, DispatchType } from './types';
import { IPost } from '../interfaces/IPost';

export const setToast = (toastConfig: any): AppAction => {
	return {
		type: actionTypes.SET_TOAST,
		toast: toastConfig
	}
}

export const showProgressSpinner = (): AppAction => {
	return {
		type: actionTypes.SHOW_PROGRESS_SPINNER
	}
}

export const hideProgressSpinner = (): AppAction => {
	return {
		type: actionTypes.HIDE_PROGRESS_SPINNER
	}
}

export const showPopUp = (): AppAction => {
	return {
		type: actionTypes.SHOW_POPUP
	}
}

export const hidePopUp = (): AppAction => {
	return {
		type: actionTypes.HIDE_POPUP
	}
}

export const setSelectedPost = (post: IPost | null): AppAction => {
	return {
		type: actionTypes.SET_SELECTED_POST,
		post
	}
}

export const setMapViewport = (viewPortConfig: any): AppAction => {
	return {
		type: actionTypes.SET_VIEWPORT,
		viewPortConfig
	}
}

export const getControlPanelTree = () => {
	const action: AppAction = {
		type: actionTypes.GET_CPANEL_TREE
	}
	return getTreeHttpRequest(action)
}

export const getPosts = () => {
	const action: AppAction = {
		type: actionTypes.GET_POSTS
	}

	return getPostsHttpRequest(action)
}

function getTreeHttpRequest(action: AppAction) {
	return (dispatch: DispatchType) => {
		const service = new ApiService()
		service.getTree().then((result) => {
			dispatch({...action, tree: result.data});
		})
	};
}

function getPostsHttpRequest(action: AppAction) {
	return (dispatch: DispatchType) => {
		const service = new ApiService()
		service.getPosts().then((result) => {
			dispatch({...action, posts: result.data});
		})
	};
}
