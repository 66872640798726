import axios, { AxiosInstance } from 'axios'
import { API_ENDPOINT } from './ApiEndpoint';

export class ApiService {
	axiosService: AxiosInstance

	constructor() {
		this.axiosService = axios.create({
			baseURL: API_ENDPOINT,
		})
	}

	getCategories() {
		return this.axiosService.get('categories')
	}

	getTree() {
		return this.axiosService.get('categories/tree')
	}

	getPosts() {
		return this.axiosService.get('posts')
	}

	submitPost(formData: FormData) {
		return this.axiosService.post('posts/submitPost', formData, {
			headers: { "Content-Type": "multipart/form-data" }
		})
	}
}
