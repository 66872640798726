import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { isBrowser } from 'react-device-detect';

export const AboutDialog = (props: any) => {
	const {visible, onHide} = props

	const dialogStyle = isBrowser ? {width: '50vw'} : {width: '95vw'}

	return (
		<Dialog header="about" visible={visible} style={dialogStyle} onHide={onHide}>
			<p>Hong Kong, as it has long existed in the cultural imaginary, began to disappear on June 30, 2020 with
				the enactment of the PRC’s new national security law. Those of us who love Hong Kong, who hold it in our
				hearts, for whom it is a first or second (or third) home… our collective hearts break at the methodical
				dissolution of our political and social contracts. This is a love letter to Hong Kong. A time capsule.
				A memory wall. A collection. A menagerie of lost things. A living archive. An epitaph. A resurrection spell.
				This is an act of protest. A bloodletting. A silent scream. This is a message in a bottle. A lighthouse.
				A post-it on a wall. This is a thousand yellow umbrellas turned against the sky. This is illegal.
				This is necessary. This is a map to our memories of a place that isn’t yet gone… and might less darkly
				find its way.</p>
		</Dialog>
	)
}
