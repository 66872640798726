import React, { useEffect, useMemo, useState } from 'react';

import MapGL, {
  FlyToInterpolator,
  FullscreenControl,
  GeolocateControl, Layer, Marker,
  NavigationControl,
  Popup,
  ScaleControl, Source,
} from 'react-map-gl';
import './App.css';
import { PostInfo } from './components/post-info'
import { ControlPanel } from './components/control-panel';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { IPost } from './interfaces/IPost';
import { MapEvent } from 'react-map-gl/src/components/interactive-map';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';

import * as mapboxgl from "mapbox-gl";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  getControlPanelTree,
  getPosts,
  hidePopUp,
  setMapViewport,
  setSelectedPost,
  showPopUp
} from './store/actionCreators';
import { AppState } from './store/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// const TOKEN = 'pk.eyJ1IjoiaGt3aXNpb24iLCJhIjoiY2ttMDMxeDlhMDV4bzJ2cDh1eWppb3dpNCJ9.T1BUuuQJWLUCPg32ydPQjA' //anton
const TOKEN = 'pk.eyJ1IjoidW5jbGV3aXNoYm9uZSIsImEiOiJja21pMzhoYWwwZGRsMzJtdTM5ajNsdWcwIn0.7mZ67mtabhO3AtVMTSzuxg'
const geolocateStyle = {
  top: 0,
  left: 0,
  padding: '10px'
};

const fullscreenControlStyle = {
  top: 36,
  left: 0,
  padding: '10px'
};

const navStyle = {
  top: 72,
  left: 0,
  padding: '10px'
};

const scaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: '10px'
};

const SIZE = 40;


function App() {

  const dispatch: Dispatch<any> = useDispatch()

  const mapViewPort: any = useSelector(
      (state: AppState) => state.mapViewPort,
      shallowEqual
  );

  const cPanelTree: any = useSelector(
      (state: AppState) => state.controlPanelTree,
      shallowEqual
  );

  const appPosts: IPost[] =  useSelector(
      (state: AppState) => state.posts,
      shallowEqual
  );

  const selectedPost: IPost =  useSelector(
      (state: AppState) => state.selectedPost,
      shallowEqual
  );

  const isPopupVisible: boolean =  useSelector(
      (state: AppState) => state.showPopUp,
      shallowEqual
  );

  const [clickedLocation, setClickedLocation] = useState(null)

  const getCursor = ({isHovering, isDragging}: any) => {
    return isDragging ? 'grabbing' : isHovering ? 'pointer' : 'pointer';
  }

  const onClosePopup = () => {
    dispatch(hidePopUp())
    dispatch(setSelectedPost(null))
    dispatch(setMapViewport({
        ...mapViewPort,
        pitch: 1
      }))
  }

  const onMapClick = (evt: MapEvent) => {
    if (isPopupVisible) {
      dispatch(hidePopUp())
      dispatch(setSelectedPost(null))
      dispatch(setMapViewport({
        ...mapViewPort,
        pitch: 1
      }))
    } else {

      const features: any[] = evt.features.filter((feature: any) => {
        return feature.layer.source.includes('jsx-source')
      })

      if(features.length > 0) {
        const foundPost = appPosts.find((post: IPost) => {
          return post.id === features[0].layer.id
        })
        dispatch(setSelectedPost(foundPost))
        dispatch(showPopUp())
      } else {
        setClickedLocation(evt.lngLat)
      }
    }
  }

  const onPostClick = (post: IPost) => {
    dispatch(hidePopUp())
    dispatch(setSelectedPost(post))

    let latitude
    let longitude
    if (post.location.type === 'Point') {
      longitude = post.location.coordinates[0]
      latitude = post.location.coordinates[1]
    }
    if (post.location.type === 'LineString') {
      longitude = post.location.coordinates[0][0]
      latitude = post.location.coordinates[0][1]
    }
    dispatch(setMapViewport({
      latitude,
      longitude,
      zoom: 15,
      bearing: 10,
      pitch: 0,
      // @ts-ignore
      transitionInterpolator: new FlyToInterpolator({speed: 1.2}),
      transitionDuration: 'auto'
    }))
  }


  const onTransitionEnd = () => {
    if (selectedPost) {
      dispatch(showPopUp())
    }
  }

  const onViewPortChange = (e: any) => {
    dispatch(setMapViewport(e))
  }

  const markers = useMemo(() => appPosts.map((post: IPost, index: number) => {
    const divStyle = {
      cursor: 'pointer'
    }
    if (post.location.type === 'Point') {
      return (
          <Marker key={`marker-${index}`} longitude={post.location.coordinates[0]}
                  latitude={post.location.coordinates[1]}>
            <div style={divStyle} onClick={() => onPostClick(post)}>
              <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color={post.category.markerColor} />
            </div>
          </Marker>
      )
    }
    if(post.location.type === 'LineString') {
      const lineColor = post.category.markerColor
      return (
          <div key={index}>
            <Source type="geojson" data={{
              id: post.id,
              type: 'Feature',
              properties: {},
              geometry: post.location
            }}>
              <Layer type="line"
                     id={post.id}
                  // source={post.id}
                     source="post"
                     layout={{
                       'line-join': 'round',
                       'line-cap': 'round'
                     }}
                     paint={{
                       'line-color': lineColor,
                       'line-width': 8
                     }}/>
            </Source>

          </div>
      )

    }

  }), [appPosts]);

  useEffect(() => {
    dispatch(getControlPanelTree())
    dispatch(getPosts())
  }, [])

  // const mapBoxStyle = 'mapbox://styles/hkwision/ckmf1rz1x0p2x17lbtdtk7ln6'
  // const mapBoxStyle = 'mapbox://styles/hkwision/ckmf1w1li2bsx17pl7o6z6fut'
  const mapBoxStyle = 'mapbox://styles/unclewishbone/ckmi3opx10s0g17nm2fwgcapz'

  return (
      <>

        <MapGL
            {...mapViewPort}
            width="100vw"
            height="100vh"
            mapStyle={mapBoxStyle}
            onViewportChange={onViewPortChange}
            onTransitionEnd={onTransitionEnd}
            onClick={onMapClick}
            getCursor={getCursor}
            mapboxApiAccessToken={TOKEN}
        >
          {/*<Pins posts={appPosts} onClick={onPostClick}/>*/}
          <>{markers}</>
          {isPopupVisible && (
              <Popup
                  tipSize={10}
                  anchor={ isBrowser ? 'right' : 'bottom'}
                  longitude={selectedPost.location.type === 'Point' ? selectedPost.location.coordinates[0]: selectedPost.location.coordinates[0][0]}
                  latitude={selectedPost.location.type === 'Point' ? selectedPost.location.coordinates[1] : selectedPost.location.coordinates[0][1]}
                  captureScroll={ true }
                  closeOnClick={ false}
                  dynamicPosition={ false }
                  onClose={onClosePopup}
              >
                <PostInfo info={selectedPost}/>

              </Popup>
          )}

          <BrowserView>
            <GeolocateControl style={geolocateStyle}/>
            <FullscreenControl style={fullscreenControlStyle}/>
            <NavigationControl style={navStyle}/>
            <ScaleControl style={scaleControlStyle}/>
          </BrowserView>
          <MobileView>
            <NavigationControl style={navStyle} showCompass={false} showZoom={false}/>
          </MobileView>

        </MapGL>

        <ControlPanel tree={cPanelTree} onClick={onPostClick} clickedLocation={clickedLocation}/>
      </>

  );
}

export default App;
